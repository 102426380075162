import { Row, Select, SelectProps } from 'antd';
import { Field, FieldProps } from 'formik';
import { CSSProperties, FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  type?: string;
  label: string;
  labelStyle?: CSSProperties;
  readOnly?: boolean;
  disabled?: boolean;
  formattedOptions: string[];
}

export const FieldInputSelect: FC<Props> = ({ fieldName, type, label, labelStyle, readOnly = false, disabled = false, formattedOptions, ...rest }) => {
  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => (
        <Row style={{ width: '100%' }}>
          <FormLabel style={labelStyle} label={label} />

          <Select
            {...field}
            allowClear
            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
            onChange={(val) => setFieldValue(fieldName, val)}
            disabled={disabled}
            status={meta.error && meta.touched ? 'error' : undefined}
            onFocus={() => setFieldTouched(fieldName, false)}
            style={{ width: '100%' }}
            options={formattedOptions.map((option) => ({ label: option, value: option }))}
          />
          <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
        </Row>
      )}
    </Field>
  );
};
