import { HeaderControls } from 'components/molecules/HeaderControls';
import { OutageLocationsGrid } from 'components/molecules/OutageLocationsGrid';

export const OutageLocationPage = (): JSX.Element => {
  return (
    <>
      <HeaderControls />
      {/* {isSelectedCard ? <SelectedLocationCard address={isSelectedCard} addressType={'shipping'} /> : <ShippingLocationsGrid addressType="shipping" />} */}
      {<OutageLocationsGrid addressType="shipping" />}
    </>
  );
};
