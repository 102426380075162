import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, InputNumber, List, Row, Space, Typography, message } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { OutageModelPayload } from 'models/OutageModel';
import { GetInventoryOnHandResponse, Product, ProductPayload } from 'models/Product';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/store';
import { AntCardTitle } from './AntCardTitle';
const { Text } = Typography;

interface Props {
  product: Product | Partial<Product>;
}

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    height: '100%'
  },
  description: {
    fontSize: '0.8em',
    display: 'flex'
  },
  bottomControls: {
    justifyContent: 'space-between',
    width: '100%',
    justifySelf: 'flex-end'
  },
  quantityInput: {
    width: '60px',
    textAlign: 'center'
  },
  currentCart: {
    textAlign: 'right'
  }
};

export const ProductGridItem = ({ product }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  // const { data: favoritesData, isLoading: isFavLoading, isFetching: isFavFetching } = useGetUserFavoritesQuery();

  const { isCommonStock } = useSelector((state: ReduxState) => state.juliaProductsParams);
  const { setFieldValue, values } = useFormikContext<OutageModelPayload>();
  // const { data: requestConfigData } = useGetDeploymentRequestConfigQuery();
  const [hover, setHover] = useState<string | null>(null);

  // const { data, isLoading, isError, error } = useGetInventoryOnHandQuery(
  //   {
  //     deploymentRequestTypeId: values.deploymentRequestTypeId as string,
  //     product: product.productNumber as string,
  //     includeCommonStock: isCommonStock !== undefined ? (isCommonStock ? true : false) : false
  //   },
  //   { skip: !values.deploymentRequestTypeId || !product.productNumber }
  // );
  const selectedProduct = values.lines?.find((selectedProduct) => selectedProduct.productNumber === product.productNumber);
  const inputRef = useRef<HTMLInputElement>(null);

  // Pulling products from all favorites lists into one flat array
  // const flatData = favoritesData?.lists.map((list) => list.products).flat();
  // Finding if the product is included in the flat array above to switch the favorite icon out
  // const isFavorited = flatData?.some((prod) => prod.productNumber === product.productNumber);

  /* ******************** Functions / Variables ******************** */
  const handleChangeQuantity = (): void => {
    //console.log('product', product);
    if (inputRef.current && Number(inputRef.current.value) > 0) {
      const quantity = Number(inputRef.current.value);

      if (selectedProduct) {
        setFieldValue('lines' as keyof OutageModelPayload, [
          ...(values.lines ?? []).filter((product) => product.productNumber !== selectedProduct.productNumber),
          { ...selectedProduct, quantity: selectedProduct.quantity + quantity }
        ]);
      } else {
        const payloadProductObject: ProductPayload = {
          alternateItemId: product?.customerXRef?.alternateItemId ? product?.customerXRef?.alternateItemId : null,
          id: product.id,
          category: product.category,
          manufacturer: product.manufacturer || 'n/a',
          productDescription: product.productDescription,
          productName: product.productName,
          productNumber: product.productNumber ?? '',
          quantity: quantity,
          customerStandardCost: product.customerXRef ? product.customerXRef.customerStandardCost || 0 : 0
        };

        setFieldValue('lines' as keyof OutageModelPayload, [...(values.lines ?? []), payloadProductObject]);
      }
      inputRef.current.value = '0';
    } else {
      message.warn('Please add a valid quantity...');
    }
  };

  const handleGetTotalQuantity = (allInventoryTotals: GetInventoryOnHandResponse[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: any[]) => arr.reduce((sum, { quantityAvailable }) => sum + quantityAvailable, 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  /* ******************** Render ******************** */
  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      <Card
        size="small"
        headStyle={{ paddingLeft: 8, paddingRight: 8 }}
        bodyStyle={{ padding: 8, marginTop: 'auto' }}
        style={styles.container}
        hoverable
        title={
          <div style={{ display: 'flex', flexDirection: 'column', height: 75 }}>
            <Row>
              <Space>
                {/* <ListMenu product={product}>
                  <Button
                    style={{ border: 'none' }}
                    loading={isFavLoading || isFavFetching}
                    onMouseEnter={(): void => setHover('favorite')}
                    onMouseLeave={(): void => setHover(null)}
                    icon={
                      <Lottie
                        animationData={isFavorited ? favoriteFilledLottie : favoriteLottie}
                        loop={false}
                        goTo={!hover ? 0 : undefined}
                        play={hover === 'favorite'}
                        style={isFavorited ? { width: 30, height: 30, display: 'inline-block', transform: 'scale(1.5)' } : { width: 25, height: 25, display: 'inline-block', transform: 'scale(1.5)' }}
                      />
                    }
                  />
                </ListMenu> */}
                <AntCardTitle level={3} text={product.productNumber ?? ''} />
              </Space>
            </Row>
            <Row justify="space-between">
              <Col>
                <Space size={1} direction="vertical">
                  <Text type="secondary" style={{ fontSize: 12 }}>
                    {product.productName}
                  </Text>
                  <Text type="secondary" style={{ fontSize: 12, minHeight: 20 }}>
                    {product?.customerXRef !== null ? product?.customerXRef?.alternateItemId : ''}
                  </Text>
                </Space>
              </Col>
              <Col>
                <Space direction="vertical">
                  <Space style={styles.bottomControls} size={15}>
                    <div />
                    <Input.Group compact>
                      {/* <FancyNumberInput value={undefined} isCurrency={false} onInputChange={() => console.log('input change')} placeHolder="1" /> */}
                      <InputNumber ref={inputRef} style={styles.quantityInput} defaultValue="1" precision={0} type="int" />
                      <Button onClick={handleChangeQuantity} type="ghost" icon={<PlusOutlined />} />
                    </Input.Group>
                  </Space>
                </Space>
              </Col>
            </Row>
          </div>
        }>
        <Row gutter={[8, 8]} align="top" style={{ minHeight: 60, paddingBottom: 10 }}>
          <Col span={24}>
            <Text type="secondary" style={styles.description}>
              {product.productDescription}
            </Text>
          </Col>
        </Row>
        <Row gutter={[8, 8]} align="bottom">
          <Col span={8}>
            <Space direction="vertical">
              <Text>Category</Text>
              <Text type="secondary">{product.category || 'NA'}</Text>
            </Space>
          </Col>
          <Col span={16} style={{ textAlign: 'right' }}>
            <Space direction="vertical">
              <Text style={{ color: '#5867dd' }}>{selectedProduct ? `Cart Contains : ${selectedProduct.quantity}` : null}</Text>
            </Space>
          </Col>
          <Col span={8}>
            <Space direction="vertical">
              <Text>Manufacturer</Text>
              <Text type="secondary">{product.manufacturer || 'NA'}</Text>
            </Space>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
