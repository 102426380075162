import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import React from 'react';

interface Props extends TitleProps {
  label: string;
  isSwitch?: boolean;
}

export const FormLabel: React.FC<Props> = ({ label }) => {
  return (
    <Typography.Title level={5} style={{ color: '#2F5596' }}>
      {label}
    </Typography.Title>
  );
};
