import { FancyInput } from 'components/atoms/FancyInput';
import { AddressLabels } from 'models/Address';
import React from 'react';
import { setStreet1Contains } from 'redux/services/chuckieSue/addressesParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const StreetFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setStreet1Contains(value || undefined));
  }, 300);

  return <FancyInput placeholder={AddressLabels.street1} onChange={handleChange} />;
};
