import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Menu, Row, Space, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSelectedAddress } from 'redux/slices/formSlice';

export const ActionControls = (): JSX.Element => {
  const { submitForm } = useFormikContext();
  const nav = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const isEdit = pathname.includes('edit');

  const menuJSX = (
    <Menu>
      <Menu.Item
        onClick={(): void => {
          submitForm();
        }}
        key="1">
        Save
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    return () => {
      dispatch(setSelectedAddress(undefined));
    };
  }, []);

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Text style={{ fontSize: 20 }}>{isEdit ? 'Edit Outage Ticket' : 'Create Outage Ticket'}</Typography.Text>
      </Col>
      <Col>
        <Space>
          <Button onClick={() => nav('/')} icon={<ArrowLeftOutlined />}>
            Back
          </Button>
          <Button
            disabled={isEdit}
            type="primary"
            onClick={(): void => {
              submitForm();
            }}
            //   overlay={menuJSX}
          >
            Save &amp; Close
          </Button>
        </Space>
      </Col>
    </Row>
  );
};
