import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address } from 'redux/services/chuckieSue/models/addresses';

interface InitialState {
  productsView: 'cart' | 'grid' | 'fav';
  isCreating: boolean;
  selectedAddress?: Address;
}

const initialState: InitialState = {
  productsView: 'grid',
  isCreating: false,
  selectedAddress: undefined
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setProductsView: (state, { payload }: PayloadAction<InitialState['productsView']>) => {
      state.productsView = payload;
    },
    setSelectedAddress: (state, { payload }: PayloadAction<InitialState['selectedAddress']>) => {
      state.selectedAddress = payload;
    },
    setIsCreating: (state, { payload }: PayloadAction<InitialState['isCreating']>) => {
      state.isCreating = payload;
    }
  }
});

export const { setProductsView, setIsCreating, setSelectedAddress } = formSlice.actions;
