import { Card, Typography } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { ProductsInfoTable } from 'components/atoms/ProductsInfoTable';
import { InlineStylesModel } from 'models/InlineStylesModel';
const { Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.orangeWeb, 0.4),
    height: '18%'
  }
};

export const ProductsInfoCard = (): JSX.Element => {
  const headerInfoCardJSX = (
    <Card
      style={{ width: '100%' }}
      bodyStyle={{ marginBottom: 0, paddingBottom: 0, height: '82%' }}
      bordered={false}
      headStyle={styles.header}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          Parts
        </Title>
      }>
      <ProductsInfoTable />
    </Card>
  );

  return headerInfoCardJSX;
};
