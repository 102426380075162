import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useFormikContext } from 'formik';
import { OutageModelPayload } from 'models/OutageModel';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setProductsView } from 'redux/slices/formSlice';
import '../../common/styles/CountStyle.css';

const CartMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<OutageModelPayload>();

  const itemCount = values.lines?.map((product) => product.quantity).reduce((previousQuantity, currentQuantity) => previousQuantity + currentQuantity, 0);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setProductsView('grid'));
    };
  }, [dispatch]);

  return (
    <>
      <Menu disabledOverflow={true} mode="horizontal" defaultSelectedKeys={['grid']}>
        <Menu.Item
          key="grid"
          icon={<SearchOutlined />}
          onClick={(): void => {
            dispatch(setProductsView('grid'));
          }}>
          Search
        </Menu.Item>
        {/* <Menu.Item
          key="fav"
          icon={<StarOutlined />}
          onClick={(): void => {
            dispatch(setProductsView('fav'));
          }}>
          Favorites
        </Menu.Item> */}
        <Menu.Item
          key="cart"
          icon={<ShoppingCartOutlined />}
          onClick={(): void => {
            dispatch(setProductsView('cart'));
          }}>
          View Cart
          {<span className="count">{itemCount ?? 0}</span>}
        </Menu.Item>
      </Menu>
    </>
  );
};

export default CartMenu;
