import { Col, Row } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect } from 'react';
import { resetParams } from 'redux/services/chuckieSue/addressesParams';
import { useAppDispatch } from 'redux/store';
import { CityFilter } from './CityFilter';
import { LocationCodeFilter } from './LocationCodeFilter';
import { LocationNameFilter } from './LocationNameFilter';
import { StreetFilter } from './StreetFilter';
import { ZipCodeFilter } from './ZipCodeFilter';

const styles: InlineStylesModel = {
  container: {
    flex: 1
  }
};

interface Props {
  locationPageType: string;
}

export const LocationsFilters = ({ locationPageType }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Effects ******************** */
  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  return (
    <Row style={styles.container} gutter={[8, 20]}>
      <Col span={6}>
        <LocationCodeFilter />
      </Col>
      <Col span={6}>
        <LocationNameFilter />
      </Col>
      <Col span={6}>
        <StreetFilter />
      </Col>
      <Col span={6}>
        <CityFilter />
      </Col>

      {/* <Col span={6}>
        <StateFilter />
      </Col> */}
      {/* <CountryStateInputs /> */}
      <Col span={6}>
        <ZipCodeFilter />
      </Col>
    </Row>
  );
};
