import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Col, Row, Space, Typography } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { LocationsFilters } from 'components/filters/addresses/LocationsFilters';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { OutageModelPayload } from 'models/OutageModel';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAddressQuery, useGetAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { useAppSelector } from 'redux/store';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.illuminatingEmerald, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  title: {
    margin: 0,
    fontWeight: 'normal',
    borderBottom: 5
  },
  nextButton: {
    margin: 'auto',
    borderRadius: '5px'
  }
};

export const HeaderControls = (): JSX.Element => {
  const nav = useNavigate();
  const { id } = useParams();
  const { values, setFieldValue } = useFormikContext<OutageModelPayload>();
  const { chuckieSueAddressesParams } = useAppSelector((state) => state);
  const [showAddAddressModal, _setShowAddAddressModal] = useState(false);

  const { data: installAddressData } = useGetAddressQuery(values.outageAddressLocationId ?? skipToken);
  // const { data, isLoading: isConfigLoading } = useGetDeploymentRequestConfigQuery();

  const { isLoading } = useGetAddressesQuery({ ...chuckieSueAddressesParams, addressTypesInclude: [LocationAddressTypes.Delivery] });

  return (
    <Row align="middle" style={styles.container}>
      <Col span={6}>
        <AntPageTitle level={3} text="Search for Outage Location" />
      </Col>
      <Col span={18} style={{ textAlign: 'right' }}>
        <Space>
          <LocationsFilters locationPageType="install" />
          {/* <Tooltip title={!data?.addressDetails.isManualAddressAllowedToBeAddedToPostOffice ? 'Account not configured to allow manual addresses' : undefined}>
            <Button disabled={!data?.addressDetails.isManualAddressAllowedToBeAddedToPostOffice} type="primary" style={styles.addAddressButton} onClick={(): void => _setShowAddAddressModal(true)}>
              Add Manual Address
            </Button>
          </Tooltip> */}

          {/* <AddDeliveryAddressModal
            isShipping
            addressTypes={data?.addressDetails?.installAddressTypes ?? []}
            show={showAddAddressModal}
            key={1}
            onClose={(): void => {
              _setShowAddAddressModal(false);
            }}
          /> */}
        </Space>
      </Col>
    </Row>
  );
};
