import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Space } from 'antd';
import { ActionControls } from 'components/molecules/ActionControls';
import { StepControls } from 'components/molecules/StepControls';
import { FormikProvider, useFormik } from 'formik';
import { OutageModelPayload, outagePayload } from 'models/OutageModel';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { useGetAddressQuery } from 'redux/services/chuckieSue/addressesApi';
import { setSelectedAddress } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';

export const EditPage = (): JSX.Element => {
  const { data } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const dispatch = useDispatch();

  const foundTicket = data.find((ticket) => ticket.outageReference === id);
  const { data: toggleAddressData } = useGetAddressQuery(foundTicket?.outageAddressLocationId ?? skipToken);

  const formik = useFormik<Partial<OutageModelPayload>>({
    enableReinitialize: true,
    validationSchema: outagePayload,
    initialValues: {
      outageReference: foundTicket?.outageReference ?? '',
      outageSubject: foundTicket?.outageSubject ?? '',
      outagePriority: foundTicket?.outagePriority ?? '',
      outageDescription: foundTicket?.outageDescription ?? '',
      outageStatus: foundTicket?.outageStatus ?? '',
      outageRequester: foundTicket?.outageRequester ?? '',
      outageContact: foundTicket?.outageContact ?? '',
      contactEmail: foundTicket?.contactEmail ?? '',
      contactPhone: foundTicket?.contactPhone ?? '',
      failureType: foundTicket?.failureType ?? '',
      failedPartNumber: foundTicket?.failedPartNumber ?? '',
      failedPartSerialNumber: foundTicket?.failedPartSerialNumber ?? '',
      outageAddressLocationId: foundTicket?.outageAddressLocationId ?? '',
      createdDateTime: foundTicket?.createdDateTime ?? new Date().toLocaleDateString(),
      totalTime: foundTicket?.totalTime ?? '0 days, 0 hours',
      lines: foundTicket?.lines ?? []
    },
    onSubmit: (values) => {
      //   dispatch(setData([...data, values]));
      //   nav('/');
      console.log(values);
    }
  });

  useEffect(() => {
    if (toggleAddressData) dispatch(setSelectedAddress(toggleAddressData));
  }, [toggleAddressData]);

  return (
    <FormikProvider value={formik}>
      <Space direction="vertical">
        <ActionControls />
        <StepControls />
        <Outlet />
      </Space>
    </FormikProvider>
  );
};
