import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Alert, Col, Row, Spin } from 'antd';
import { LocationCard } from 'components/atoms/LocationCard';
import { SelectedLocationCard } from 'components/atoms/SelectedLocationCard';
import NoResults from 'components/atoms/noResults/noResults';
import { Loader } from 'components/common/Loader';
import { LoaderProducts } from 'components/common/LoaderProducts';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { OutageModelPayload } from 'models/OutageModel';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetAddressQuery, useGetAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { incrementOffset, setAddressTypesInclude } from 'redux/services/chuckieSue/addressesParams';
import { Address, LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { useAppDispatch, useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  container: {
    flexGrow: 1,
    alignContent: 'flex-start'
  },
  selectedContainer: {
    flex: 1,
    alignItems: 'flex-start'
  }
};

interface Props {
  addressType: 'shipping' | 'install';
}

export const OutageLocationsGrid = ({ addressType }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { values } = useFormikContext<OutageModelPayload>();
  const [selectAddressData, setSelectedAddressData] = useState<Address>();

  const { chuckieSueAddressesParams } = useAppSelector((state) => state);

  const { data, isLoading, isError, isFetching } = useGetAddressesQuery({ ...chuckieSueAddressesParams, addressTypesInclude: [LocationAddressTypes.Delivery] });
  const { data: toggleAddressData, isLoading: toggleAddressIsLoading, isFetching: toggleFetching, isError: toggleAddressIsError } = useGetAddressQuery(values?.outageAddressLocationId ?? skipToken);

  const dispatch = useAppDispatch();

  const addressValue = values.outageAddressLocationId;

  /* ******************** Functions / Variables ******************** */
  const getMore = (): void => {
    dispatch(incrementOffset());
  };
  const handleSelectedAddressChange = (selectedAddress: Address): void => {
    setSelectedAddressData(selectedAddress);
  };

  /* ******************** Effects ******************** */
  // Handle division configuration
  useEffect(() => {
    // console.log(divisionData);

    return () => {
      dispatch(setAddressTypesInclude(undefined));
    };
  }, [addressType, dispatch]);

  useEffect(() => {
    if (toggleAddressData && !toggleAddressIsError) {
      setSelectedAddressData(toggleAddressData);
    }
  }, [toggleAddressData, values]);

  useEffect(() => {
    dispatch(setAddressTypesInclude([LocationAddressTypes.Delivery]));
  }, []);

  /* ******************** Loading Render ******************** */
  if (isError) {
    return <Alert type="error" message="Server error" showIcon />;
  }

  /* ******************** Error Render ******************** */
  if (isLoading || !data || (chuckieSueAddressesParams.offset === 0 && isFetching)) {
    return (
      <Row style={styles.container} gutter={[16, 16]}>
        <LoaderProducts />
      </Row>
    );
  }
  /* ******************** No Results Render ******************** */
  if (data.totalCount === 0) {
    return <NoResults errorMessage={'no locations were found...'} />;
  }

  const selectedCardJSX = (
    <Col key={values.outageAddressLocationId} span={12}>
      {selectAddressData ? <SelectedLocationCard handleRemoveInstallAddress={handleSelectedAddressChange} address={selectAddressData} addressType="shipping" /> : null}
    </Col>
  );

  const locationCardsJSX = data.data
    .filter((address) => address.id !== values?.outageAddressLocationId)
    .map((address) => {
      return (
        <Col xs={24} sm={12} md={12} key={address.id}>
          <LocationCard handleAddressSelected={handleSelectedAddressChange} addressType={addressType} address={address} />
        </Col>
      );
    });

  const hasMore = data.totalCount > chuckieSueAddressesParams.offset && data.totalCount > 25 && !values.outageAddressLocationId;

  return (
    <Spin spinning={toggleAddressIsLoading || toggleFetching} indicator={<Loader />}>
      <InfiniteScroll style={{ width: '100%', marginTop: '8px', overflow: 'visible' }} next={getMore} hasMore={hasMore} loader={<Loader />} dataLength={data.data.length}>
        <Row gutter={[16, 16]}>{values.outageAddressLocationId && values.outageAddressLocationId !== null ? [...[selectedCardJSX], ...locationCardsJSX] : locationCardsJSX}</Row>
      </InfiniteScroll>
    </Spin>
  );
};
