import { Card, Col, Row, Space, Tooltip, Typography } from 'antd';
import { formatCurrency } from 'formatters/formatters';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { GetInventoryOnHandResponse, ProductPayload } from 'models/Product';
import React from 'react';
const { Text, Title } = Typography;

interface Props {
  product: ProductPayload;
  children: React.ReactNode;
}

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    lineHeight: 1
  },
  cardBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  cardTitle: {
    width: '250px'
  },
  productInfo: { display: 'flex', flexDirection: 'column', textAlign: 'end' },
  productDescription: {
    flex: 1,
    marginBottom: 24
  }
};

export const ProductCartCard: React.FC<Props> = ({ product, children }) => {
  /* ******************** Hooks ******************** */
  const { setFieldValue, values } = useFormikContext<DeploymentRequestPayload>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment

  const handleGetTotalQuantity = (allInventoryTotals: GetInventoryOnHandResponse[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: any[]) => arr.reduce((sum, { quantityAvailable }) => sum + quantityAvailable, 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  const cardTitleJSX = (
    <div style={styles.cardTitle}>
      <Space direction="vertical">
        <Title style={{ margin: 0 }} level={4}>
          {product.productName}
        </Title>
        <Text style={{ fontWeight: 'normal', fontSize: '12px' }} type="secondary">
          {product.manufacturer} | {product.category}
        </Text>
        <Row>
          <Tooltip title={'Alternate Item Id'}>
            <Text style={{ fontWeight: 'normal', fontSize: '12px' }} type="secondary">
              {product.alternateItemId}
            </Text>
          </Tooltip>
        </Row>
      </Space>
    </div>
  );

  /* ******************** Render ******************** */
  return (
    <Card style={styles.container} title={cardTitleJSX} bodyStyle={styles.cardBody}>
      <Row>
        <Col span={16}>
          <Text style={styles.productDescription}>{product.productDescription}</Text>
        </Col>
        <Col span={4}>
          <div style={styles.productInfo}>
            {/* <Text type="success">Available: {isLoading ? <LoadingOutlined style={{ color: green.primary }} /> : handleGetTotalQuantity(data || [])}</Text> */}
            <Text type="secondary">
              Unit Cost: <Text>{formatCurrency(product.customerStandardCost)}</Text>
            </Text>
          </div>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          {children}
        </Col>
      </Row>
    </Card>
  );
};
