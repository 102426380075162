import { Col, Row } from 'antd';

import { OutageCard } from 'components/molecules/OutageCard';
import { OutageModel } from 'models/OutageModel';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';

export const OutageList = (): JSX.Element => {
  const { data, dataForList } = useAppSelector((state) => state.app);

  const [localSrc, setLocalSrc] = useState<Partial<OutageModel>[]>();

  useEffect(() => {
    if (dataForList) {
      setLocalSrc(dataForList);
    } else setLocalSrc(data ?? []);
  }, [data, dataForList]);

  return (
    <Row gutter={[16, 16]}>
      {localSrc?.map((item, idx) => {
        return (
          <Col key={idx} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
            <OutageCard outageItem={item as Partial<OutageModel>} />
          </Col>
        );
      })}
    </Row>
  );
};
