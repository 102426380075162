import { Space } from 'antd';
import { ActionControls } from 'components/molecules/ActionControls';
import { StepControls } from 'components/molecules/StepControls';
import { FormikProvider, useFormik } from 'formik';
import { OutageModelPayload, outagePayload } from 'models/OutageModel';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { setData } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export const CreatePage = (): JSX.Element => {
  const { data } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const formik = useFormik<Partial<OutageModelPayload>>({
    enableReinitialize: true,
    validationSchema: outagePayload,
    initialValues: {
      outageReference: '',
      outageSubject: '',
      outagePriority: '',
      outageDescription: '',
      outageStatus: 'New',
      outageRequester: '',
      outageContact: '',
      contactEmail: '',
      contactPhone: '',
      failureType: '',
      failedPartNumber: '',
      failedPartSerialNumber: '',
      outageAddressLocationId: '',
      createdDateTime: new Date().toLocaleDateString(),
      totalTime: '0 days, 0 hours',
      lines: []
    },
    onSubmit: (values) => {
      dispatch(setData([...data, values]));
      nav('/');
    }
  });

  return (
    <FormikProvider value={formik}>
      <Space direction="vertical">
        <ActionControls />
        <StepControls />
        <Outlet />
      </Space>
    </FormikProvider>
  );
};
