import { OutageForm } from 'components/molecules/OutageForm';
import { OutageLocationPage } from 'components/organisms/OutageLocation';
import { ProductsPage } from 'components/organisms/Products';
import { ReviewTicketPage } from 'components/organisms/ReviewTicket';
import { About } from 'pages/home/About';
import { CreatePage } from 'pages/home/Create';
import { EditPage } from 'pages/home/Edit';
import { EditProfilePage } from 'pages/home/EditProfile';
import { Home } from 'pages/home/Home';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="create" element={<CreatePage />}>
        <Route index element={<OutageForm />} />
        <Route path="outage-review" element={<ReviewTicketPage />} />
        <Route path="parts" element={<ProductsPage />} />
        <Route path="outage-address" element={<OutageLocationPage />} />
      </Route>
      <Route path="edit/:id" element={<EditPage />}>
        <Route index element={<OutageForm />} />
        <Route path="outage-review" element={<ReviewTicketPage />} />
        <Route path="parts" element={<ProductsPage />} />
        <Route path="outage-address" element={<OutageLocationPage />} />
      </Route>
      <Route path="/about" element={<About />} />
      <Route path="/profile" element={<EditProfilePage />} />
    </Routes>
  );
};
