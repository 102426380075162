import { Col, Row } from 'antd';
import CartMenu from 'components/molecules/CartMenu';
import { HeaderFilters } from 'components/molecules/HeaderFilters';
import { ProductsCart } from 'components/molecules/ProductsCart';
import { ProductsGrid } from 'components/molecules/ProductsGrid';
import { useFormikContext } from 'formik';
import { OutageModelPayload } from 'models/OutageModel';
import { setIsCommonStock } from 'redux/services/julia/productsParams';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const ProductsPage = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();
  const { productsView } = useAppSelector((state) => state.form);
  const { isCommonStock } = useAppSelector((state) => state.juliaProductsParams);
  // const { data, isError, isLoading } = useGetDeploymentRequestTypesQuery();
  const { values, setFieldValue } = useFormikContext<OutageModelPayload>();

  // const currentRuleType = data?.data.find((value) => value.id === values.deploymentRequestTypeId)?.dispositionRuleType || null;

  const handleChange = (): void => {
    dispatch(setIsCommonStock(!isCommonStock));
  };

  return (
    <>
      <HeaderFilters />
      <Row justify="space-between">
        <Col>
          <CartMenu />
        </Col>
        {/* <Col>
          {productsView !== 'fav' ? (
            <Space size={8} style={{ paddingTop: 5 }}>
              <Switch onChange={handleChange} checked={isCommonStock !== undefined ? (isCommonStock ? true : false) : false} />
              <Typography.Title style={{ margin: 'auto' }} level={5}>
                View Common Stock
              </Typography.Title>
            </Space>
          ) : null}
        </Col> */}
      </Row>

      {productsView === 'grid' ? <ProductsGrid /> : <ProductsCart />}
    </>
  );
};
