import { CloseOutlined } from '@ant-design/icons';
import { Card, CardProps, Col, Row, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';

import { colors, toRgb, toRgba } from 'common/styles/colors';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useDispatch } from 'react-redux';
import { Address } from 'redux/services/chuckieSue/models/addresses';
import { setSelectedAddress } from 'redux/slices/formSlice';
import { ReactComponent as DeliveryIcon } from '../../common/assets/delivery.svg';
import { ReactComponent as InstallIcon } from '../../common/assets/install.svg';
import { ReactComponent as WarehouseIcon } from '../../common/assets/warehouse.svg';
const { Text } = Typography;

interface Props extends CardProps {
  address: Address;
  addressType: 'shipping' | 'install';
  handleRemoveInstallAddress: any;
}

const styles: InlineStylesModel = {
  container: {
    height: '100%'
  },
  cardBody: {
    // padding: 10,
    // paddingLeft: 15
    borderRadius: 10,
    padding: 0
  },
  customerInfo: {
    padding: '10px 60px'
  },
  title: {
    fontSize: '.8rem',
    margin: 0,
    color: 'rgba(0, 0, 0, .4)'
  },
  titleWhite: {
    fontSize: '.8rem',
    margin: 0,
    color: 'rgba(255, 255, 255, .4)'
  },
  values: {
    fontSize: 16,
    fontWeight: 600
  },
  valuesWhite: {
    color: 'rgba(255, 255, 255, .9)',
    fontSize: 16,
    fontWeight: 600
  },
  minusButton: {
    color: '#F93943',
    borderColor: '#F93943'
  },
  selectedInstallStyles: {
    transition: '.25s all ease-out',
    background: toRgba(colors.royalBlueLight, 0.5),
    boxShadow: '0 0 6px rgba(0,0,0,.4)',
    borderRadius: 10
  },
  selectedShippingStyles: {
    transition: '.25s all ease-out',
    background: toRgba(colors.illuminatingEmerald, 0.5),
    boxShadow: '0 0 6px rgba(0,0,0,.4)',
    borderRadius: 10
  },
  removeButton: {
    margin: 'auto'
  }
};

export const SelectedLocationCard = ({ address, addressType, handleRemoveInstallAddress, ...rest }: Props): JSX.Element => {
  console.log(address, 'address');
  /* ******************** Hooks ******************** */
  const { setFieldValue, values } = useFormikContext<DeploymentRequestPayload>();
  const dispatch = useDispatch();

  /* ******************** Functions ******************** */
  const handleRemoveInstallLocation = (): void => {
    setFieldValue('outageAddressLocationId', undefined);
    dispatch(setSelectedAddress(undefined));
  };

  if (!address) {
    return <></>;
  }

  return (
    <Card bordered={false} style={addressType === 'install' ? styles.selectedInstallStyles : styles.selectedShippingStyles} bodyStyle={styles.cardBody} hoverable onClick={handleRemoveInstallLocation}>
      <Row style={{ borderRadius: 10 }}>
        <Col span={10} style={{ background: 'rgba(40,40,40,.6)', padding: '0px 20px', borderRadius: '10px 0 0 10px', paddingTop: '5px' }}>
          <Row gutter={[5, 0]} style={{ paddingTop: '10px' }}>
            {address?.addressType?.includes('Install') ? (
              <Col>
                <Tooltip title="Install" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
                  <InstallIcon width={20} />
                </Tooltip>
              </Col>
            ) : null}
            {address?.addressType?.includes('Delivery') ? (
              <Col>
                <Tooltip title="Delivery" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
                  <DeliveryIcon width={25} />
                </Tooltip>
              </Col>
            ) : null}
            {address?.addressType?.includes('Warehouse') ? (
              <Col>
                <Tooltip title="Warehouse" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
                  <WarehouseIcon width={20} />
                </Tooltip>
              </Col>
            ) : null}
          </Row>
          <Typography.Paragraph style={styles.titleWhite}>
            <Tooltip title="Address Code" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
              {address?.code || <span>&nbsp;</span>}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={styles.valuesWhite}>
            {' '}
            <Tooltip title="Address Name" placement="left" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
              {address?.name || <span>&nbsp;</span>}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
        <Col span={12}>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: '81.25px',
              height: '45.625px',
              borderLeft: 'solid 40.625px rgba(40,40,40,.6)',
              borderBottom: 'solid 50.625px transparent',
              borderTop: 'solid 50.625px transparent'
            }}
          />
          <Col style={styles.customerInfo}>
            <Typography.Paragraph style={styles.titleWhite}>Address</Typography.Paragraph>
            <Typography.Paragraph style={styles.valuesWhite}>
              {address?.street1} {address?.city}, {address?.subdivision?.localCode} {address?.country?.alpha2Code}
            </Typography.Paragraph>
          </Col>
        </Col>
        <Col span={1} style={styles.removeButton}>
          <CloseOutlined style={{ fontSize: 28, color: toRgb(colors.redSalsa) }} />
        </Col>
      </Row>
    </Card>
  );
};
