import { Row, Space, Typography } from 'antd';
import { FC } from 'react';
import Lottie from 'react-lottie-player';

interface Props {
  message: string;
  animationData: object;
  customSize?: {
    width: number;
    height: number;
  };
}

export const WaitToSearch: FC<Props> = ({ message, animationData, customSize }) => {
  return (
    <Row justify="center">
      <Space direction="vertical">
        <Lottie style={customSize ? customSize : { width: 275, height: 275 }} animationData={animationData} loop play />
        <Row justify="center">
          <Typography.Text style={{ fontSize: 20 }}>{message}</Typography.Text>
        </Row>
      </Space>
    </Row>
  );
};
