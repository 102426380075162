import { Col, Row } from 'antd';
import { GeneralInfoCard } from 'components/molecules/GeneralInfoCard';
import { OutageInfoCard } from 'components/molecules/OutageInfoCard';
import { ProductsInfoCard } from 'components/molecules/ProductsInfoCard';
import { InlineStylesModel } from 'models/InlineStylesModel';

const styles: InlineStylesModel = {
  headerContainer: {
    backgroundColor: 'white',
    padding: 24
  },
  headerTitle: {
    margin: 0,
    fontWeight: 'normal'
  },
  container: {
    flex: 1
  }
};

export const ReviewTicketPage = (): JSX.Element => {
  // const { id } = useParams();
  // const { data: deploymentRequest } = useGetDeploymentRequestQuery(id || skipToken);

  return (
    <Row gutter={[8, 8]} style={styles.container}>
      <Col sm={24} md={12}>
        <OutageInfoCard />
      </Col>
      <Col sm={24} md={12}>
        <GeneralInfoCard />
      </Col>
      <Col sm={24} md={12}>
        <ProductsInfoCard />
      </Col>
      {/* <Col sm={24} md={12}>
        <ShippingInfoContent />
      </Col>
      <Col sm={24} md={12}>
        <OrderInfoContent />
      </Col>
      <Col sm={24} md={12}>
        <ProductsContent />
      </Col>
      <Col sm={24} md={12}>
        {deploymentRequest && deploymentRequest?.attachments.length >= 1 ? <AttachmentsContent /> : null}
      </Col> */}
    </Row>
  );
};
