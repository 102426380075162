import { Menu, Space } from 'antd';
import { deliveryLottie, headerInfoLottie, productsLottie, reviewOrderLottie } from 'common/assets/customer-lottie';
import { LottieIcon } from 'components/atoms/LottieIcon';
import { useFormikContext } from 'formik';
import { useGetWizardStep } from 'hooks/useGetWizardStep';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { OutageModelPayload } from 'models/OutageModel';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export enum CreateRequestMenu {
  HeaderInfo = 'general',
  OutageDetails = 'outage-address',
  Parts = 'parts',
  Overview = 'outage-review'
}

const styles: InlineStylesModel = {
  wrapper: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    overflow: 'hidden'
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 40
  },
  navIcon: {
    paddingRight: 20,
    paddingLeft: 20,
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'transparent'
  }
};

export const StepControls = (): JSX.Element => {
  const dispatch = useDispatch();
  const { wizardStep } = useGetWizardStep();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { values } = useFormikContext<OutageModelPayload>();

  const [playLottie, setPlayLottie] = useState(false);

  // const customerStepGreen = Boolean(values.customerAccount);
  const headerInfoStepGreen = Boolean(
    values.outageReference &&
      values.outageSubject &&
      values.outagePriority &&
      values.failureType &&
      values.outageDescription &&
      values.outageStatus &&
      values.outageRequester &&
      values.outageContact &&
      values.contactEmail &&
      values.contactPhone
  );
  const deliveryStepGreen = Boolean(values.outageAddressLocationId);
  const productStepGreen = Boolean((values?.lines?.length ?? 0) > 0);
  // const emailStepGreen = Boolean(values.customerEmailAddresses.length > 0);
  // const attachmentStepGreen = Boolean(values.attachments && values.attachments.length > 0);

  const isDuplicatePage = pathname.includes('duplicate');
  const isViewPage = pathname.includes('view');

  const handleNavigationCreate = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.HeaderInfo) {
      navigate('/create');
    } else if (info.key === CreateRequestMenu.Parts) {
      navigate('/create/parts');
    } else if (info.key === CreateRequestMenu.OutageDetails) {
      navigate('/create/outage-address');
    } else if (info.key === CreateRequestMenu.Overview) {
      navigate('/create/outage-review');
    }
  };

  const handleNavigationEdit = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.HeaderInfo) {
      navigate(`/edit/${id}`);
    } else if (info.key === CreateRequestMenu.Parts) {
      navigate(`/edit/${id}/parts`);
    } else if (info.key === CreateRequestMenu.OutageDetails) {
      navigate(`/edit/${id}/outage-address`);
    } else if (info.key === CreateRequestMenu.Overview) {
      navigate(`/edit/${id}/outage-review`);
    }
  };
  // const handleNavigationView = (info: { key: string }): void => {
  //   if (info.key === CreateRequestMenu.CustomerSelection) {
  //     navigate(`/view/${id}`);
  //   } else if (info.key === CreateRequestMenu.HeaderInfo) {
  //     navigate(`/view/${id}/header-info`);
  //   } else if (info.key === CreateRequestMenu.Email) {
  //     navigate(`/view/${id}/email-info`);
  //   } else if (info.key === CreateRequestMenu.Attachments) {
  //     navigate(`/view/${id}/attachments`);
  //   } else if (info.key === CreateRequestMenu.Products) {
  //     navigate(`/view/${id}/products`);
  //   } else if (info.key === CreateRequestMenu.DeliveryDetails) {
  //     navigate(`/view/${id}/delivery-address`);
  //   } else if (info.key === CreateRequestMenu.Overview) {
  //     navigate(`/view/${id}/quote`);
  //   }
  // };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Menu onClick={id ? handleNavigationEdit : handleNavigationCreate} mode="horizontal" style={{ justifyContent: 'center', height: 48 }} selectedKeys={[wizardStep]}>
        <Menu.Item key={CreateRequestMenu.HeaderInfo}>
          <LottieIcon required title="General" speed={0.7} complete={headerInfoStepGreen} lottieFile={headerInfoLottie} />
        </Menu.Item>
        <Menu.Item key={CreateRequestMenu.OutageDetails}>
          <LottieIcon title="Outage Address" complete={deliveryStepGreen} lottieFile={deliveryLottie} />
        </Menu.Item>
        <Menu.Item key={CreateRequestMenu.Parts}>
          <LottieIcon title="Parts" complete={productStepGreen} lottieFile={productsLottie} />
        </Menu.Item>
        <Menu.Item key={CreateRequestMenu.Overview}>
          <LottieIcon title="Review Outage Ticket" complete={null} lottieFile={reviewOrderLottie} />
        </Menu.Item>
      </Menu>
    </Space>
  );
};
