import * as yup from 'yup';
import { ProductPayload } from './Product';
export interface OutageModel {
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  documentType: string;
  id: string;
  isActive: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  outageReference: string;
  outageSubject: string;
  outagePriority: string;
  outageDescription: string;
  outageStatus: string;
  outageRequester: string;
  outageContact: string;
  contactEmail: string;
  contactPhone: string;
  failureType: string;
  failedPartNumber: string;
  failedPartSerialNumber: string;
  partitionKey: string;
  partitionKeyDescription: string;
  totalTime: string;
  outageAddressLocationId: string;
  lines: ProductPayload[];
}
export interface OutageModelPayload {
  outageReference: string;
  outageSubject: string;
  outagePriority: string;
  outageDescription: string;
  outageStatus: string;
  outageRequester: string;
  outageContact: string;
  contactEmail: string;
  contactPhone: string;
  failureType: string;
  failedPartNumber?: string;
  failedPartSerialNumber?: string;
  outageAddressLocationId?: string;
  createdDateTime?: string;
  lines?: ProductPayload[];
  totalTime?: string;
}

export const outagePayload: yup.ObjectSchema<OutageModelPayload> = yup.object({
  outageReference: yup.string().label('Outage Reference').required(),
  outageSubject: yup.string().label('Outage Subject').required(),
  outagePriority: yup.string().label('Outage Priority').required(),
  outageDescription: yup.string().label('Outage Description').required(),
  outageStatus: yup.string().label('Outage Status').required(),
  outageRequester: yup.string().label('Outage Requester').required(),
  outageContact: yup.string().label('Outage Contact').required(),
  contactEmail: yup.string().label('Contact Email').required(),
  contactPhone: yup.string().label('Contact Phone').required(),
  failureType: yup.string().label('Failure Type').required(),
  failedPartNumber: yup.string().label('Failed Part Number'),
  failedPartSerialNumber: yup.string().label('Failed Part Serial Number'),
  outageAddressLocationId: yup.string().label('Outage Address').required(),
  createdDateTime: yup.string(),
  totalTime: yup.string(),
  lines: yup.array().label('Products')
});
