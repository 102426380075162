import { Button, Card, Col, Row, Typography } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { OutageModelPayload } from 'models/OutageModel';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
const { Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.robinEggBlue, 0.4),
    height: '18%'
  },
  infoText: {
    // marginBottom: 0,
    // paddingBottom: 0
  }
};

export const OutageInfoCard = (): JSX.Element => {
  const { values } = useFormikContext<OutageModelPayload>();
  const { selectedAddress } = useAppSelector((state) => state.form);
  const navigate = useNavigate();
  const { id } = useParams();

  const headerInfoCardJSX = (
    <Card
      style={{ height: 350, width: '100%' }}
      bodyStyle={{ marginBottom: 0, paddingBottom: 0, height: '82%' }}
      bordered={false}
      headStyle={styles.header}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          Outage Information
        </Title>
      }>
      <Row gutter={16} style={{ height: '100%' }}>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Reference ID:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.outageReference}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Subject:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.outageSubject}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Failure Type:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.failureType}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Failed Part Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.failedPartNumber ?? 'No part entered'}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Failed Serial Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.failedPartSerialNumber ?? 'No serial number entered'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Priority
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.outagePriority}</Typography.Paragraph>
        </Col>
        <Col span={12}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Outage Location:
          </Typography.Title>
          {selectedAddress ? (
            <Typography.Paragraph style={styles.infoText}>
              {selectedAddress?.name}: {selectedAddress?.code} <br /> {selectedAddress?.street1 + ' ' + selectedAddress?.street2} , {selectedAddress?.city} {selectedAddress?.subdivision.localCode}{' '}
              {selectedAddress?.postalCode} {selectedAddress?.country.alpha2Code}
            </Typography.Paragraph>
          ) : (
            <Button onClick={(): void => navigate(id ? `/edit/${id}/outage-address` : '/create/outage-address')}>Select Outage Location</Button>
          )}
        </Col>
      </Row>
    </Card>
  );

  return headerInfoCardJSX;
};
