import { Business } from './Business';
import { DivisionReference } from './DeploymentRequest';

export interface Product {
  alternateItem: null | { id: string };
  business: Business;
  category: string;
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: DivisionReference;
  documentType: string;
  hasSubstitutions: boolean;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  isItemConfigurable: boolean;
  isKit: boolean;
  manufacturer: string;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKey: string;
  partitionKeyDescription: string;
  productDescription: string;
  productName: string;
  productNumber: string;
  searchName: string;
  salesNewPrice: number;
  customerXRef: {
    alternateItemId: null | string;
    customerStandardCost: null | number;
  };
}
export interface ProductPayload {
  alternateItemId?: string | null;
  id?: string;
  category?: string;
  manufacturer?: string;
  productDescription?: string;
  productName?: string;
  productNumber: string;
  quantity: number;
  customerStandardCost?: number;
  isPosted?: boolean;
  customerXRef?: {
    alternateItemId: null | string;
    customerStandardCost: null | number;
  };
}

export enum ProductLabels {
  categoryName = 'Category',
  manufacturerName = 'Manufacturer',
  productDescription = 'Product Description',
  productName = 'Product Name',
  productNumber = 'Product Number',
  quantity = 'Quantity',
  standardCost = 'Standard Cost'
}
// export const productSchema: yup.ObjectSchema<ProductPayload> = yup.object({
//   alternateItemId: yup.string().nullable(),
//   id: yup.string().required(),
//   category: yup.string().required(),
//   manufacturer: yup.string().required(),
//   productDescription: yup.string().required(),
//   productName: yup.string().required(),
//   productNumber: yup.string().required(),
//   quantity: yup.number().required(),
//   customerStandardCost: yup.number().required(),
//   isPosted: yup.boolean(),
//   customerXRef: new yup.ObjectSchema().nullable()
// });

export interface GetProductsResponse {
  totalCount: number;
  data: Product[];
}

export interface GetProductsParams {
  // TODO: Add rest
  offset: number;
  manufacturerNameContains?: string;
  categoryNameContains?: string;
  productNameContains?: string;
  productDescriptionContains?: string;
  productNumberContains?: string;
  alternateItemIdContains?: string;
  isKit?: string;
  isItemConfigurable?: string;
  hasSubstitutions?: string;
  isCommonStock?: boolean;
  deploymentTypeEquals?: string;
}

export interface GetInventoryOnHandResponse {
  conditionId: string;
  dataAreaId: string;
  dispositionId: string;
  itemId: string;
  ownerId: string;
  quantityAvailable: number;
  siteId: string;
  warehouseId: string;
}

export interface InventoryOnHandPayload {
  deploymentRequestTypeId: string;
  product: string;
  includeCommonStock?: boolean;
}
