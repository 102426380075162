import { Col, Row, Space, Typography } from 'antd';
import { FieldInput } from 'components/formItems/FieldInput';
import { FieldInputSelect } from 'components/formItems/FieldInputSelect';
import { FieldTextArea } from 'components/formItems/FieldTextArea';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';

const styles: InlineStylesModel = {
  headerTitleRow: {
    height: 60,
    padding: '0px 20px',
    backgroundColor: '#b0b2bc'
  },
  salesTitleRow: {
    height: 60,
    padding: '0px 20px',
    backgroundColor: '#FFEAC1'
  },
  contactTitleRow: {
    height: 60,
    padding: '0px 20px',
    backgroundColor: '#CED5F2'
  },
  title: {
    marginBottom: 0
  },
  mainContainer: {
    backgroundColor: 'white',
    padding: '30px',
    paddingBottom: '0px',
    height: '100%'
  }
};

export const OutageForm = (): JSX.Element => {
  const { values } = useFormikContext();

  return (
    <Row gutter={[10, 70]}>
      <Col span={12}>
        <Row style={styles.headerTitleRow}>
          <Space size="large">
            <Col>
              <Typography.Title level={4} style={styles.title}>
                Outage Info
              </Typography.Title>
            </Col>
          </Space>
        </Row>

        <Row style={styles.mainContainer}>
          <Row gutter={[30, 30]} style={{ width: '100%' }}>
            <Col span={12}>
              <Space direction="vertical" size={10} style={{ width: '100%' }}>
                <FieldInput label="Outage Reference" fieldName="outageReference" />
                <FieldInput label="Outage Subject" fieldName="outageSubject" />
                <FieldInputSelect formattedOptions={['Low', 'Normal', 'High', 'Urgent']} label="Outage Priority" fieldName="outagePriority" />
                <FieldInputSelect
                  formattedOptions={[
                    'CPU',
                    'Drive',
                    'Fan',
                    'Power Supply',
                    'Sysytem Board',
                    'Controller',
                    'Other',
                    'Memory Board',
                    'Memory DIMM',
                    'Processor Board',
                    'Riser Board',
                    'Daughter Board',
                    'Storage',
                    'Storage ESM/LCC',
                    'PCI',
                    'Chasis',
                    'Battery',
                    'Tape Drives'
                  ]}
                  label="Failure Type"
                  fieldName="failureType"
                />
                <FieldInput label="Failed Part Number" fieldName="failedPartNumber" />
                <FieldInput label="Failed Part S/N" fieldName="failedPartSerialNumber" />
              </Space>
            </Col>
            <Col span={12}>
              <FieldTextArea label="Description" fieldName="outageDescription" />
            </Col>
          </Row>
        </Row>
      </Col>
      <Col span={12}>
        <Row style={styles.salesTitleRow}>
          <Space size="large">
            <Col>
              <Typography.Title level={4} style={styles.title}>
                General Info
              </Typography.Title>
            </Col>
          </Space>
        </Row>

        <Row style={styles.mainContainer}>
          <Space direction="vertical" size={10} style={{ width: '100%' }}>
            <FieldInputSelect formattedOptions={['New', 'Active', 'Cancelled', 'Closed']} label="Status" fieldName="outageStatus" />
            <FieldInput label="Requester" fieldName="outageRequester" />
            <FieldInput label="Outage Contact" fieldName="outageContact" />
            <FieldInput label="Contact Email" fieldName="contactEmail" />
            <FieldInput label="Contact Phone Number" fieldName="contactPhone" />
            {/* <FieldInput label="Failed Part S/N" fieldName="failedPartSerialNumber" /> */}
          </Space>
        </Row>
      </Col>
    </Row>
  );
};
