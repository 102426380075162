import { Col, Row, Space, Typography } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { CategoryFilter, ManufacturerFilter } from 'components/filters/products';
import { AlternateIdFilter } from 'components/filters/products/AlternateIdFilter';
import { ProductDescriptionFilter } from 'components/filters/products/ProductDescriptionFilter';
import { ProductNumberFilter } from 'components/filters/products/ProductNumberFilter';
import { formatCurrency } from 'formatters/formatters';
import { useFormikContext } from 'formik';
import { DeploymentRequestLabels } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { OutageModelPayload } from 'models/OutageModel';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
const { Title } = Typography;

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.romanSilver, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  title: {
    margin: 0,
    fontWeight: 'normal'
  },
  textColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  totalStyle: {
    fontSize: '20px',
    textAlign: 'center'
  },
  nextButton: {
    borderRadius: '5px',
    textAlign: 'right'
  }
};
const FilterGroup = (): JSX.Element => {
  // const { bulkSearchResults } = useAppSelector((state: ReduxState) => state.app);

  // const { clearValues } = useBulkSearch();

  return (
    <Col span={24}>
      <Row gutter={[10, 15]}>
        <Col span={8}>
          <ProductNumberFilter />
        </Col>
        <Col span={8}>
          <AlternateIdFilter />
        </Col>
        <Col span={8}>
          <ProductDescriptionFilter />
        </Col>
        <Col span={8}>
          <CategoryFilter />
        </Col>
        <Col span={8}>
          <ManufacturerFilter />
        </Col>
        {/* <Col span={8}>
          <Row justify="end">
            <Space>
              {!!bulkSearchResults?.length && <Button onClick={clearValues}>Reset</Button>}
              <ProductNumberBulkSearch />
            </Space>
          </Row>
        </Col> */}
      </Row>
    </Col>
  );
};

export const HeaderFilters = (): JSX.Element => {
  const nav = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { values } = useFormikContext<OutageModelPayload>();
  const itemCount = values.lines?.map((product) => product.quantity).reduce((previousQuantity, currentQuantity) => previousQuantity + currentQuantity, 0);
  const totalCost = values.lines
    ?.map((product) => product.customerStandardCost && product.customerStandardCost * product.quantity)
    .reduce((previousCost, currentCost) => (previousCost || 0) + (currentCost || 0), 0);

  const { productsView } = useAppSelector((state) => state.form);

  const handleContinueButton = (): void => {
    id ? nav(`/edit/${id}/request-info`) : nav('/create/request-info');
  };

  // if (productsView === 'fav')
  //   return (
  //     <Row style={styles.container}>
  //       <Col>
  //         <Title level={4} style={styles.title}>
  //           User Favorites
  //         </Title>
  //       </Col>
  //       <Col>
  //         <Space>
  //           <FavoriteListFilter />
  //           <FavoriteListSettings />
  //         </Space>
  //       </Col>
  //     </Row>
  //   );

  return (
    <div style={styles.container}>
      <Space style={{ flex: 3, justifyContent: 'center' }} size={0} direction="vertical">
        <Title level={4} style={styles.title}>
          {DeploymentRequestLabels.products} {productsView === 'grid' ? 'Search' : 'Cart'}
        </Title>
      </Space>
      <Row style={{ flex: 6 }} gutter={[8, 8]}>
        {productsView === 'grid' ? (
          <FilterGroup />
        ) : (
          <Col md={24}>
            <Row>
              <Col span={3}></Col>
              <Col span={9} style={styles.totalStyle}>
                Total Cost: {formatCurrency(totalCost)}
              </Col>
              <Col span={1}></Col>
              <Col span={9} style={styles.totalStyle}>
                Total Items: {itemCount}
              </Col>
              <Col span={2}></Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};
