import { Button, Row, Space, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { WaitToSearch } from 'components/common/waitToSearch/WaitToSearch';
import { useFormikContext } from 'formik';
import { OutageModelPayload } from 'models/OutageModel';
import { ProductPayload } from 'models/Product';
import { useNavigate, useParams } from 'react-router-dom';
import shoppingCartLottie from '../../common/assets/shopping-cart.json';

export const ProductsInfoTable = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    values: { lines }
  } = useFormikContext<OutageModelPayload>();

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal'
  });

  const cols: ColumnType<ProductPayload>[] = [
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      width: '20%',
      fixed: 'left'
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      width: '10%'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: '10%'
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      width: '5%',
      align: 'center',
      render: (text: string, record): JSX.Element => {
        return <div>{numberFormatter.format(Number(record.quantity))}</div>;
      }
    }
  ];

  return (
    <Table
      style={{ marginBottom: 32 }}
      locale={{
        emptyText: (
          <Space size={'large'} direction="vertical" style={{ width: '100%' }}>
            <WaitToSearch customSize={{ width: 100, height: 100 }} animationData={shoppingCartLottie} message="Cart is empty" />
            <Row style={{ width: '100%' }} justify="center">
              <Button onClick={(): void => navigate(id ? `/edit/${id}/parts` : '/create/parts')}>Add products</Button>
            </Row>
          </Space>
        )
      }}
      pagination={false}
      columns={cols}
      dataSource={lines}
    />
  );
};
