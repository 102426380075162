import { Card, Col, Row, Typography } from 'antd';
import { colors, toRgba } from 'common/styles/colors';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { OutageModelPayload } from 'models/OutageModel';
const { Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.illuminatingEmerald, 0.4),
    height: '18%'
  },
  infoText: {}
};

export const GeneralInfoCard = (): JSX.Element => {
  const { values } = useFormikContext<OutageModelPayload>();

  const headerInfoCardJSX = (
    <Card
      style={{ height: 350, width: '100%' }}
      bodyStyle={{ marginBottom: 0, paddingBottom: 0, height: '82%' }}
      bordered={false}
      headStyle={styles.header}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          General Information
        </Title>
      }>
      <Row gutter={16} style={{ height: '100%' }}>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Status:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.outageStatus}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Requester:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.outageRequester}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Contact Email:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.contactEmail}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Contact Phone:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.contactPhone}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Outage Contact
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.outageContact}</Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );

  return headerInfoCardJSX;
};
