import { Card, Col, Divider, Row, Tooltip, Typography } from 'antd';
import { colors, toRgb } from 'common/styles/colors';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { OutageModel } from 'models/OutageModel';
import { useNavigate } from 'react-router-dom';

interface Props {
  outageItem: Partial<OutageModel>;
}

const styles: InlineStylesModel = {
  card: {
    borderRadius: 5
  },
  cardBody: {
    padding: 10,
    paddingRight: 15,
    paddingLeft: 15
  },
  largeText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#000'
  },
  title: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#636770'
  },
  subTitle: {
    fontSize: '15px',
    color: '#A0A3AE',
    minHeight: '27px'
  },
  text: {
    fontSize: '13px',
    color: '#A0A3AE'
  },
  smallText: {
    fontSize: '11px',
    color: 'lightGrey'
  },
  status: {
    fontSize: '16px',
    fontWeight: 700
  },
  draft: {
    color: toRgb(colors.orangeWeb)
  },
  sent: {
    color: toRgb(colors.robinEggBlue)
  },
  awarded: {
    color: toRgb(colors.illuminatingEmerald)
  },
  cancelled: {
    color: toRgb(colors.redSalsa)
  },
  lost: {
    color: toRgb(colors.redSalsa)
  },
  soNumber: {
    fontSize: '14px',
    color: '#5868DD'
  },
  poNumber: {
    fontSize: '14px',
    color: 'gray'
  },
  customerId: {
    fontSize: '16px',
    color: '#636770'
  },
  iconMenuButton: {
    width: '100%'
  },
  iconMenuButtonDuplicate: {
    width: '100%',
    color: toRgb(colors.illuminatingEmerald)
  }
};

export const OutageCard: React.FC<Props> = ({ outageItem }: Props) => {
  const nav = useNavigate();
  const handleClick = (): void => {
    nav(`/edit/${outageItem.outageReference}`);
  };

  const outagePriorityStyle = (): React.CSSProperties => {
    if (outageItem?.outagePriority?.toLowerCase() === 'urgent') return { ...styles.status, ...styles.lost };
    if (outageItem?.outagePriority?.toLowerCase() === 'high') return { ...styles.status, ...styles.draft };
    if (outageItem?.outagePriority?.toLowerCase() === 'normal') return { ...styles.status, ...styles.sent };
    if (outageItem?.outagePriority?.toLowerCase() === 'low') return { ...styles.status, ...styles.awarded };

    return styles.status;
  };

  return (
    <Card onClick={handleClick} style={styles.card} bodyStyle={styles.cardBody} hoverable>
      <Row gutter={8} justify="space-between">
        <Col span={18}>
          <Typography.Text ellipsis={{ tooltip: `${outageItem.outageReference}` }} style={styles.largeText}>
            <Tooltip title="Outage Refernece" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.outageReference}
            </Tooltip>
          </Typography.Text>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Typography style={outagePriorityStyle()}>
            <Tooltip title="Outage Priority" placement="left" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.outagePriority}
            </Tooltip>
          </Typography>
        </Col>
      </Row>
      <Row gutter={8} justify="space-between">
        <Col>
          <Typography style={styles.subTitle}>
            <Tooltip title="Outage Subject" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.outageSubject}
            </Tooltip>
          </Typography>
        </Col>
        <Col>
          <Typography style={styles.customerId}>
            <Tooltip title="Outage Requester" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.outageRequester}
            </Tooltip>
          </Typography>
        </Col>
      </Row>
      <Row gutter={8} justify="space-between">
        <Col>
          <Typography style={styles.subTitle}>
            <Tooltip title="Failure Type" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.failureType}
            </Tooltip>
          </Typography>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Typography style={styles.text}>
            <Tooltip title="Created By" placement="left" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.createdByFullName}
            </Tooltip>
          </Typography>
        </Col>
      </Row>
      <Row gutter={8} justify="space-between">
        <Col></Col>
        <Col onClick={(e): void => e.stopPropagation()} style={{ textAlign: 'right' }}>
          {/* <Popover
            content={<IconMenu closePopover={handleCloseChange} salesQuote={salesQuote} />}
            trigger="click"
            placement="left"
            open={open}
            onOpenChange={handleOpenChange}
            overlayInnerStyle={{ margin: 0, padding: 0, borderRadius: 10, width: 200 }}>
            <GoldOutlined
              onClick={(e): void => {
                e.stopPropagation();
              }}
              style={{ fontSize: 24 }}
            />
          </Popover> */}
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between">
        <Col style={{ textAlign: 'start', marginLeft: 10 }} span={10}>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }}>
            <Tooltip title="Outage Contact" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.outageContact}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }}>
            <Tooltip title="Creation Date" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {new Date(outageItem.createdDateTime ?? '').toLocaleDateString()}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }} ellipsis={{ tooltip: outageItem.outageStatus }}>
            <Tooltip title="Failed Part Number" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.failedPartNumber}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
        <Row>
          <Col span={2}>
            <Divider type="vertical" style={{ fontSize: 80 }} />
          </Col>
        </Row>

        <Col style={{ textAlign: 'end', marginRight: 10 }} span={10}>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }} ellipsis={{ tooltip: outageItem.outageContact }}>
            <Tooltip title="Open Ticket Time" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.totalTime}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }} ellipsis={{ tooltip: outageItem.outageContact }}>
            <Tooltip title="Last Modified" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {new Date().toLocaleDateString()}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }}>
            <Tooltip title="Failed Part S/N" placement="bottom" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {outageItem.failedPartSerialNumber}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );
};
