import { CreateRequestMenu } from 'components/molecules/StepControls';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useGetWizardStep = (): { wizardStep: string } => {
  const { pathname } = useLocation();
  const wizardStep = useMemo(() => {
    if (pathname.includes(CreateRequestMenu.Parts)) {
      return CreateRequestMenu.Parts;
    } else if (pathname.includes(CreateRequestMenu.Overview)) {
      return CreateRequestMenu.Overview;
    } else if (pathname.includes(CreateRequestMenu.OutageDetails)) {
      return CreateRequestMenu.OutageDetails;
    } else {
      return CreateRequestMenu.HeaderInfo;
    }
  }, [pathname]);

  return { wizardStep };
};
