import { useEffect, useState } from 'react';

const getWindowDimensions = (): { height: number; width: number } => {
  const { innerHeight: height, innerWidth: width } = window;

  return {
    height,
    width
  };
};

export const useGetDimensions = (): { height: number; width: number } => {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = (): void => {
      setDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);

    return window.removeEventListener('resize', handleResize);
  }, []);

  return dimensions;
};
