import { FancyInput } from 'components/atoms/FancyInput';
import { ProductLabels } from 'models/Product';
import React, { useEffect } from 'react';
import { setCategoryNameContains } from 'redux/services/julia/productsParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const CategoryFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();
  // const { clearValues } = useBulkSearch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // clearValues();
    dispatch(setCategoryNameContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setCategoryNameContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder={ProductLabels.categoryName} onChange={handleChange} />;
};
